/* @flow */

import {
  requestGetProducts,
  requestGetProduct,
  requestGetProductComments,
  processSnapshotsArr,
  requestGetTags,
  requestGetTypes,
  requestGetBrands,
  requestGetSuppliers,
  requestAddProductComment,
  requestAddProductToFavorites,
  requestRemoveProductFromFavorites,
  requestGetUserFavorites,
  requestDeleteProductComment,
  requestAddOrder,
  requestGetUserOrders,
  requestGetOrders,
  requestUpdateOrder,
  requestUpdateStateOrder,
  subscribeToOrderChanges,
  requestGetProductsByList,
  requestGetOrder,
  
  
} from './client';

import c from '../constants';

let cacheData = {};

export function loadCacheData() {
  const aux = localStorage.getItem('cacheData');
  if (aux) {
    cacheData = { ...JSON.parse(aux), ...cacheData };
  }
}
export function saveCacheData(obj:Object) {
  cacheData = { ...cacheData, ...obj };
  localStorage.setItem('cacheData', JSON.stringify(cacheData));
}
export function getCacheData():Object {
  return cacheData;
}

export function getTagsList():Function {
  return (dispatch: Function):Promise<any> => {
    if (
      cacheData.tags &&
      cacheData.tagsDate > new Date().getTime() - 1000 * 60 * 20 // 20 minutes
    ) {
      return Promise.resolve(cacheData.tags);
    }
    return requestGetTags().then((querySnapshot) => {
      const tags = processSnapshotsArr(querySnapshot)
        .map((obj:Object) => {
          return {
            value: obj.id,
            label: obj.data.name || obj.id,
            count: obj.data.count,
            list: obj.data.list,
          };
        });
      saveCacheData({
        tags,
        tagsDate: new Date().getTime(),
      });
      return tags;
    });
  };
}

export function getTypesList():Function {
  return (dispatch: Function):Promise<any> => {
    if (
      cacheData.productTypes &&
      cacheData.productTypesDate > new Date().getTime() - 1000 * 60 * 20 // 20 minutes
    ) {
      return Promise.resolve(cacheData.productTypes);
    }
    return requestGetTypes().then((querySnapshot) => {
      const productTypes = processSnapshotsArr(querySnapshot)
        .map((obj:Object) => {
          return {
            value: obj.id,
            label: obj.data.name || obj.id,
            count: obj.data.count,
            list: obj.data.list,
          };
        });
      saveCacheData({
        productTypes,
        productTypesDate: new Date().getTime(),
      });
      return productTypes;
    });
  };
}

export function getBrandsList():Function {
  return (dispatch: Function):Promise<any> => {
    if (
      cacheData.productBrands &&
      cacheData.productBrandsDate > (new Date().getTime() - 1000 * 60 * 20) // 20 minutes
    ) {
      return Promise.resolve(cacheData.productBrands);
    }
    return requestGetBrands().then((querySnapshot) => {
      const productBrands = processSnapshotsArr(querySnapshot)
        .map((obj:Object) => {
          return {
            value: obj.id,
            label: obj.data.name || obj.id,
            count: obj.data.count,
            list: obj.data.list,
          };
        });
      saveCacheData({
        productBrands,
        productBrandsDate: new Date().getTime(),
      });
      return productBrands;
    });
  };
}

export function getSuppliersList():Function {
  return (dispatch: Function):Promise<any> => {
    if (
      cacheData.productSuppliers &&
      cacheData.productSuppliersDate > new Date().getTime() - 1000 * 60 * 20 // 20 minutes
    ) {
      return Promise.resolve(cacheData.productSuppliers);
    }
    return requestGetSuppliers().then((querySnapshot) => {
      const productSuppliers = processSnapshotsArr(querySnapshot)
        .map((obj:Object) => {
          return {
            value: obj.id,
            label: obj.data.name || obj.id,
            count: obj.data.count,
            list: obj.data.list,
          };
        });
      saveCacheData({
        productSuppliers,
        productSuppliersDate: new Date().getTime(),
      });
      return productSuppliers;
    });
  };
}

let lastQuery = {};
export function getProductsList(
  props:Object = {},
  startAfterDate:any = null,
):Function {
  let params:Object = {};
  if (props.type != null) params.type = props.type;
  if (props.barcode != null && props.barcode.length > 0) params.barcode = props.barcode;
  if (props.sku != null &&  props.sku.length > 0) params.sku  = props.sku;
  if (props.tags != null) params.tags = props.tags;
  return (dispatch: Function) => {
    params.limit = 25;
    return requestGetProducts(params, startAfterDate)
      .then((querySnapshot):Array<Object> => {
        const newProducts = processSnapshotsArr(querySnapshot)
          .map((obj: Object) => {
            return obj;
          });
        dispatch({
          type: c.UPDATE_PRODUCTS,
          payload: newProducts,
          add: startAfterDate != null,
        });
        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        if (querySnapshot.docs.length < params.limit) {
          return null;
        }
        return lastVisible;
      });
  };
}

export function getProduct(productId:Object):Function {
  return (dispatch: Function) => {
    return requestGetProduct(productId).then((doc) => {
      if (doc.exists) {
        const res = doc.data();
        dispatch({
          type: c.UPDATE_PRODUCT,
          payload: {
            ...res,
            id: productId,
          },
        });
        return res;
      }
      return { error: true };
    });
  };
}

export function getProductsComments(productId:Object):Function {
  return (dispatch: Function) => {
    const updateComments = (comments:Array<Object>) => {
      dispatch({
        type: c.UPDATE_COMMENTS,
        payload: {
          id: productId,
          comments: comments.map(o => ({
            ...o,
            data: {
              ...o.data,
              timestamp: o.data.timestamp.toDate(),
            },
          })),
        },
      });
    };
    return requestGetProductComments(productId, {
      subscribe: false,
    }).then((comments) => {
      updateComments(comments);
      return comments;
    });
  };
}

export function addProductComment(productId:Object, comment:Object, rating:Object):Function {
  return (dispatch: Function) => {
    return requestAddProductComment(productId, comment, rating).then((res) => {
      dispatch({
        type: c.UPDATE_COMMENTS,
        payload: {
          id: productId,
          comments: [res],
        },
      });
      return res;
    });
  };
}

export function deleteProductComment(productId, commentId) {
  return (dispatch) => {
    return requestDeleteProductComment(productId, commentId).then((res) => {
      dispatch({
        type: c.DELETE_COMMENT,
        payload: {
          id: productId,
          commentId: commentId,
        },
      });
      return res;
    });
  };
}



export function toggleProductFromFavorite(
  product,
  isFavorite,
) {
  return (dispatch, getState) => {
    let toggleRequest;
    if (isFavorite) {
      toggleRequest = requestRemoveProductFromFavorites(product.id);
    } else {
      toggleRequest = requestAddProductToFavorites(product.id);
    }

    return toggleRequest
      .then(() => {
        if (isFavorite) {
          dispatch({
            type: c.REMOVE_PRODUCT_TO_FAVORITES,
            payload: product,
          });
        } else {
          dispatch({
            type: c.ADD_PRODUCT_TO_FAVORITES,
            payload: product,
          });
        }
      })
      .catch((error) => {
        console.error('Error toggling favorite:', error);
        throw error; 
      });
  };
}

export function getFavoritesList() {
  return async (dispatch) => {
    try {
      const favorites = await requestGetUserFavorites(); 
      dispatch({
        type: c.UPDATE_FAVORITES,
        payload: favorites,
      });
      return favorites;
    } catch (error) {
      console.error('Error fetching favorites:', error);
    }
  };
}


export function addOrder(order) {
  return (dispatch) => {
    return requestAddOrder(order).then((res) => {
      dispatch({
        type: c.ADD_ORDER,
        payload: res,
      });
      dispatch({
        type: c.CLEAR_CART,
      });
    }).catch((error) => {
      console.error('Error adding order:', error);
    });
  };
}

export function getUserOrdersList():Function {
  return async (dispatch) => {
    try {
      const orders = await requestGetUserOrders(); 
      dispatch({
        type: c.UPDATE_ORDERS,
        payload: orders,
      });
      return orders;
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };
}

export const handleOrderChanges = (callback) => {
  return (dispatch, getState) => {
    const { orders } = getState().products;
    const handleChange = (orderData) => {
      const { type, orderId, data } = orderData; 
      let message = '';
      const orderExists = orders.filter(order => order.id === orderId).length;

      if (type === 'added') {
        switch (data.state) {
          case 'En cola': {
            callback({ message: `Orden en cola`, orderData });
            break;
          }
          case 'Confirmado': {
            callback({ message: `Se confirmó la orden`, orderData });
            break;
          }
          case 'Finalizado':
            break;
          case 'Cancelado':
            break;
          case 'No disponible':
            break;
          default:
            console.warn('Not Registered', data);
            break;
        }

        if (orderExists) {
          dispatch({
            type: 'UPDATE_ORDER',
            payload: { id: orderId, data }, 
          });
        } else {
          dispatch({
            type: 'ADD_ORDER',
            payload: { id: orderId, data }, 
          });
        }
      }
    };

    const unsubscribe = subscribeToOrderChanges(handleChange);

    return unsubscribe;
  };
};

export function getOrdersList(cb):Function {
  return async (dispatch) => {
    try {
      const orders = await requestGetOrders(); 
      dispatch({
        type: c.UPDATE_ORDERS,
        payload: orders,
      });

      dispatch(handleOrderChanges(({ message, orderData }) => {
        cb(message, orderData);
      }));

      return orders;
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };
}

export function changeOrder(updatedOrder, orderId) {
  return async (dispatch) => {
    try {
      const { id, data } = await requestUpdateOrder(updatedOrder, orderId); 
      dispatch({
        type: c.UPDATE_ORDER,
        payload: { id, data }, 
      });
      return data; 
    } catch (error) {
      console.error('Error updating order:', error);
      throw error;
    }
  };
}

export function changeStateOrder(orderId, newState) {
  return async (dispatch) => {
    try {
      const { id, data } = await requestUpdateStateOrder(orderId, newState);
      dispatch({
        type: c.UPDATE_ORDER,
        payload: { id, data }, 
      });
      return data;
    } catch (error) {
      console.error('Error updating order:', error);
      throw error;
    }
  };
}

export function getOrder(orderId) {
  return async (dispatch) => {
    try {
      const order = await requestGetOrder(orderId);
      dispatch({
        type: c.ADD_ORDER,
        payload: {
          id: orderId,
          data: order.data,
        },
      });
      const productList = order.data.products || [];
      const productIds = productList.map(product => product.id);
      
      if (productIds.length > 0) {
        await dispatch(getProductsByList(productIds));
      }

      return order;
    } catch (error) {
      console.error('Error al obtener y procesar el pedido:', error);
      return { success: false, error };
    }
  };
}

export function getProductsByList(productList) {
  return async (dispatch, getState) => {
    try {
      if (productList.length === 0) {
        dispatch({
          type: c.UPDATE_PRODUCTS,
          payload: [],
        });
        return [];
      }
      const productsData = await requestGetProductsByList(productList);
      const state = getState();
      const currentProducts = state.products.list || []; 
      const updatedProducts = [
        ...currentProducts,
        ...productsData.filter(newProduct => 
          !currentProducts.some(existingProduct => existingProduct.id === newProduct.id)
        )
      ];
      dispatch({
        type: c.UPDATE_PRODUCTS,
        payload: productsData,
      });
      
      return productsData; 
    } catch (error) {
      console.error('Error getting products:', error);
      throw error;
    }
  };
}