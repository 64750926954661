/* @flow */

import {
  requestCreateBranch,
  uploadFirebaseImage,
  requestGetBranches,
} from './client';

import c from '../constants';

export function uploadImage(file: Object, props: Object) {
  return uploadFirebaseImage(file, props);
}


export function handleUploadImageFile(
  image: Object,
  progressFn: Function = () => {},
  options: Object = {},
): Promise<any> {
  return new Promise((resolve) => {
    import('fabric').then(({ fabric }) => {
      function fnUploadImage(fileImage: Object) {
        const preview = URL.createObjectURL(fileImage);
        uploadImage(fileImage, {
          folder: options.pathFolder || 'nea/branches/original',
          progressFn,
          forceId: options.userAvatar ? 'avatar' : null,
        }).then((newIdImage) => {
          resolve({
            id: newIdImage,
            image: fileImage,
            preview,
          });
        });
      }

      const canvas = new fabric.Canvas('canvasupload', {
        imageSmoothingEnabled: false,
        enableRetinaScaling: false,
      });

      fabric.Image.fromURL(URL.createObjectURL(image), (img) => {
        let imgWidth = img.width;
        let imgHeight = img.height;

        if (imgWidth <= 1200 && imgHeight <= 1200) {
          fnUploadImage(image);
        } else {
        
          const limitImg = 1200;
          const ratioSize = Math.min(
            limitImg / imgWidth,
            limitImg / imgHeight
          );

          img.set({
            top: 0,
            left: 0,
            width: imgWidth,
            height: imgHeight,
            scaleX: ratioSize,
            scaleY: ratioSize,
          });

          canvas.setWidth(img.getScaledWidth());
          canvas.setHeight(img.getScaledHeight());
          canvas.add(img);
          canvas.renderAll();

          canvas.lowerCanvasEl.toBlob((canvasBlob) => {
            fnUploadImage(canvasBlob);
          }, 'image/jpeg', 1);
        }
      });
    });
  });
}


export function createBranch(branchData: Object): Function {
  return (dispatch: Function) => {
    return requestCreateBranch(branchData).then((res) => {
      dispatch({
        type: c.ADD_BRANCH,
        payload: res, 
      });
      return res; 
    }).catch((error) => {
      console.error('Error creating branch:', error);
      throw error; 
    });
  };
}



export function getBranches() {
  return async (dispatch) => {
    return requestGetBranches().then((res) => {
      dispatch({
        type: c.UPDATE_BRANCHES,
        payload: res,
      });
      return res;
    }).catch((error) => {
      console.error('Error al obtener las sucursales:', error);
      throw error; 
    });
  };
}

