/* @flow */

import {
  requestGetHome,
} from './client';
import {
  getCacheData,
  saveCacheData,
} from './products';

import c from '../constants';

export function getHome():Function {
  return async (dispatch) => {
    const cacheData = getCacheData();
    if (
      cacheData.home &&
      cacheData.homeDate > new Date().getTime() - 1000 * 60 * 10 // 10 minutes
    ) {
      dispatch({
        type: c.UPDATE_HOME,
        payload: cacheData.home,
      });
      return cacheData.home;
    }
    try {
      const homeData = await requestGetHome();
      dispatch({
        type: c.UPDATE_HOME,
        payload: homeData,
      });
      saveCacheData({
        home: homeData,
        homeDate: new Date().getTime(),
      });
      return homeData; 
    } catch (error) {
      console.error('Error obteniendo productos del carrusel:', error);
      throw error;
    }
  };
}
