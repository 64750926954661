/* @flow */

import deepMerge from 'lodash/merge';
import Notification from 'rsuite/Notification';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOutAuth,
  requestSaveAuthorData,
  requestGetAuthorData,
  requestSetProductToCart,
  requestRemoveProductFromCart,
  requestRemoveVariantFromCart,
} from './client';
import { getFavoritesList,  getUserOrdersList, getOrdersList} from './products'; 
import { getCashRegister} from '../actions/cashRegister';
import {getBranches} from './branches';
import c from '../constants';

function onError(error:Object) {
  Notification['error']({
    title: 'Error',
    description: error.message,
  });
}

let authStateChangedRegistered = false;
export function registerAuthStateChanged(cb:Function):Function {
  return (dispatch: Function) => {
    if (authStateChangedRegistered) return;
    authStateChangedRegistered = true;
    onAuthStateChanged((userRes) => {
      if (userRes) {
        requestGetAuthorData(userRes.uid).then((data) => {
          dispatch({
            type: c.SET_USER,
            payload: {
              ...userRes,
              ...data,
              cart: undefined,
            },
          });
          dispatch({
            type: c.UPDATE_CART,
            payload: {
              ...data.cart,
            },
          });
          dispatch(getFavoritesList(userRes.uid));
          dispatch(getBranches()); 
          dispatch(getUserOrdersList());//ordenes como cliente
          dispatch(getCashRegister());
          if(data.admin) {
            dispatch(getOrdersList(cb));//ordenes como admin
          }
        }).catch(onError); 
      } else {
        dispatch({
          type: c.SIGN_OUT,
        });
      }
    });
  };
}

export function createEmailPass(email: string, password: string):Promise<any> {
  return createUserWithEmailAndPassword(email, password)
    .then((userCredential) => {
      return userCredential;
    });
}

export function signInEmailPass(email:string, password:string):Promise<any> {
  return signInWithEmailAndPassword(email, password);
}

export function saveAccountData(userUid:string, data:Object):Function {
  return (dispatch: Function) => {
    return requestSaveAuthorData(userUid, data).then(() => {
      dispatch({
        type: c.UPDATE_USER,
        payload: data,
      });
    });
  };
}

export function signOut():Function {
  return (dispatch: Function) => {
    signOutAuth();
    dispatch({
      type: c.SIGN_OUT,
    });
  };
}

export function addProductToCart(productId, variant, quantity) {
  return (dispatch, getState) => {
    const state = getState();
    const oldCart = deepMerge({}, state.account.cart); 

    let cartData = {};

    if (variant) {
      if (oldCart[productId] && typeof oldCart[productId] === 'object' && !oldCart[productId].quantity) {
        const updatedProduct = deepMerge({}, oldCart[productId], {
          [variant.name]: {
            quantity,
            timestamp: new Date(),
          },
        });
        cartData = {
          ...oldCart,
          [productId]: updatedProduct,
        };
      } else {
        cartData = {
          ...oldCart,
          [productId]: {
            [variant.name]: {
              quantity,
              timestamp: new Date(),
            },
          },
        };
      }
    } else {
      cartData = {
        ...oldCart,
        [productId]: {
          quantity,
          timestamp: new Date(),
        },
      };
    }
    dispatch({
      type: c.UPDATE_CART,
      payload: cartData,
    });
    return requestSetProductToCart(cartData)
      .then((res) => {
      })
      .catch((err) => {
        dispatch({
          type: c.UPDATE_CART,
          payload: oldCart,
        });
        window.alert('Error adding product to cart');
      });
  };
}

export function removeProductFromCart(productId:string, variant):Function {
  return (dispatch:Function) => {
    if (variant) {
      return requestRemoveVariantFromCart(productId, variant).then((res) => {
        dispatch({
          type: c.UPDATE_CART,
          payload: {...res},
        });
      });
    } else {
      return requestRemoveProductFromCart(productId).then(() => {
        dispatch({
          type: c.REMOVE_PRODUCT_TO_CART, 
          payload: {
            id: productId,
          },
        });
      });
    }
  };
}

export function updateProductQuantityInCart (newCart:Object,):Function {
  return (dispatch, getState) => {
    const state = getState();
    const oldCart = deepMerge({}, state.account.cart);
    const cartData = {
      ...newCart,
    };
    dispatch({
      type: c.UPDATE_CART,
      payload: cartData,
    });
    return requestSetProductToCart(cartData)
      .then((res) => {
      })
      .catch((err) => {
        dispatch({
          type: c.UPDATE_CART,
          payload: oldCart,
        });
        window.alert('Error adding product to cart');
      });
  };
}